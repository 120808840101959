import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/pages/home/sections/featured-trials/index.module.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/html/index.module.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/pages/home/sections/faq/index.module.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/pages/home/sections/find-trials/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/pages/home/sections/newsletter/form.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/pages/home/sections/section.module.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/pages/home/sections/hero/index.module.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/pages/home/sections/newsletter/index.module.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/pages/home/sections/partners/index.module.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/pages/home/sections/steps/step-switcher/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/ui/collapsible-container/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/ui/small-trial-card/index.module.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/trial/trial/src/components/pages/home/sections/view-studies/index.module.scss")